import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import { Line, Seperator } from '@dex/bubl-dash';

const LevelsSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                col={{ xs: 24 }}
            >

                <Panel
                    heading={"Level Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Title",
                                key: "title",
                            },
                        ]}
                        columnB={[
                            {
                                label: "Short Title",
                                key: "shortTitle",
                            },
                        ]}
                        columnC={[
                            {
                                label: "Style",
                                key: "style",
                                format: "ModelLink",
                            },
                        ]}
                    />

                    {data.enabledFor.includes("class") &&
                        <>
                            <Seperator
                                top={"auto"}
                                bottom={"auto"}
                                heading={"Class Defaults"}
                            />

                            <MetaTable
                                data={data}
                                columnA={[
                                    {
                                        label: "Duration",
                                        key: "duration",
                                        format: "number",
                                        suffix: " mins"
                                    },
                                    {
                                        label: "Limit Leads",
                                        key: "limitLeads",
                                    },
                                    {
                                        label: "Limit Follows",
                                        key: "limitFollows",
                                    },
                                ]}
                                columnB={[
                                    {
                                        label: "Allowed Passes",
                                        key: "allowedPasses",
                                        format: "ModelLink",
                                        join: <br />
                                    },
                                    {
                                        label: "Retake Passes",
                                        key: "retakePasses",
                                        format: "ModelLink",
                                        join: <br />
                                    },
                                ]}
                                columnC={[
                                    {
                                        label: "Details",
                                        key: "details",
                                        format: "html",
                                    },
                                ]}
                            />

                        </>
                    }

                    {data.enabledFor.includes("practice") &&
                        <>
                            <Seperator
                                top={"auto"}
                                bottom={"auto"}
                                heading={"Practice Defaults"}
                            />

                            <MetaTable
                                data={data}
                                columnA={[
                                    {
                                        label: "Duration",
                                        key: "durationPractice",
                                        format: "number",
                                        suffix: " mins"
                                    },
                                    {
                                        label: "Limit Attendees",
                                        key: "limitAttendeesPractice",
                                    },
                                ]}
                                columnB={[
                                    {
                                        label: "Allowed Passes",
                                        key: "allowedPassesPractice",
                                        format: "ModelLink",
                                        join: <br />
                                    },
                                ]}
                                columnC={[
                                    {
                                        label: "Details",
                                        key: "detailsPractice",
                                        format: "html",
                                    },
                                ]}
                            />

                        </>
                    }

                    {data.enabledFor.includes("social") &&
                        <>
                            <Seperator
                                top={"auto"}
                                bottom={"auto"}
                                heading={"Socail Defaults"}
                            />

                            <MetaTable
                                data={data}
                                columnA={[
                                    {
                                        label: "Duration",
                                        key: "durationSocial",
                                        format: "number",
                                        suffix: " mins"
                                    },
                                    {
                                        label: "Limit Attendees",
                                        key: "limitAttendeesSocial",
                                    },
                                ]}
                                columnB={[
                                    {
                                        label: "Allowed Passes",
                                        key: "allowedPassesSocial",
                                        format: "ModelLink",
                                        join: <br />
                                    },
                                ]}
                                columnC={[
                                    {
                                        label: "Details",
                                        key: "detailsSocial",
                                        format: "html",
                                    },
                                ]}
                            />

                        </>
                    }


                </Panel>

            </Col>

        </Row>

    ), [data]);

}

export default LevelsSingleView;
