import React, { useCallback, useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { FieldCheckbox, FieldDate, FieldList, FieldNumber, FieldRadio, FieldRelationship, FieldRich, FieldTextTime } from '@dex/bubl-dash';


const ClassesCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit, mode } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"Class Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 16 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24 }}>

                                <FieldRadio
                                    form={form}
                                    label={"Type"}
                                    name='type'
                                    required={true}
                                    button={true}
                                    defaultValue={form.defaults.type}
                                    options={[
                                        { label: "Class", value: "class" },
                                        { label: "Practice", value: "practice" },
                                        { label: "Social", value: "social" },
                                    ]}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldRelationship
                                    form={form}
                                    label={"Style"}
                                    name='styleId'
                                    required={true}
                                    model={'Styles'}
                                    preload={true}
                                    defaultValue={form.defaults.styleId}
                                    where={{ status: "active" }}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldRelationship
                                    key={form.values.styleId + form.values.type}
                                    form={form}
                                    label={"Level"}
                                    name='levelId'
                                    required={true}
                                    model={'Levels'}
                                    preload={true}
                                    defaultValue={form.defaults.levelId}
                                    where={{ styleId: form.values.styleId, status: "active", enabledFor: { inq: [form.values.type] } }}
                                    disabled={!form.values.styleId || !form.values.type}
                                />

                            </Col>

                            {mode === "create" &&

                                <Col col={{ xs: 24, md: 16 }}>

                                    <FieldList
                                        form={form}
                                        name={'dates'}
                                        required={true}
                                        label='Date & Time'
                                        addButtonLabel={"Add Date & Time"}
                                        defaultValues={""}
                                    >

                                        {form.values.dates.map((row, index) => (

                                            <React.Fragment key={form.key + "-" + index}  >

                                                <FieldDate
                                                    form={form}
                                                    name={`dates[${index}]`}
                                                    required={true}
                                                    defaultValue={row}
                                                    time={true}
                                                />

                                            </React.Fragment>

                                        ))}

                                    </FieldList>

                                </Col>
                            }

                            {mode === "edit" &&
                                <>

                                    <Col col={{ xs: 24, md: 12 }}>

                                        <FieldDate
                                            form={form}
                                            label={"Date & Time"}
                                            name='dateTime'
                                            required={true}
                                            defaultValue={form.defaults.dateTime}
                                            time={true}
                                        />

                                    </Col>

                                    <Col col={{ xs: 24, md: 6 }}>

                                        <FieldNumber
                                            form={form}
                                            label={"Duration"}
                                            name='duration'
                                            suffix={" mins"}
                                            required={true}
                                            defaultValue={form.defaults.duration}
                                            decimalScale={0}
                                        />

                                    </Col>

                                    <Col col={{ xs: 24, md: 24 }}>

                                        <Line />

                                        <FieldCheckbox
                                            form={form}
                                            label={"Customzie"}
                                            name='customize'
                                            defaultValue={form.defaults.customize}
                                            options={[
                                                { label: "Passes", value: "passes" },
                                                { label: "Limits", value: "limits" },
                                                { label: "Details", value: "details" },
                                            ]}
                                        />

                                    </Col>

                                    {form.values.customize?.includes("passes") &&
                                        <Col col={{ xs: 24, md: 12 }}>

                                            <FieldRelationship
                                                form={form}
                                                label={"Allowed Passes"}
                                                name='allowedPassesIds'
                                                required={false}
                                                model={'Products'}
                                                preload={true}
                                                defaultValue={form.defaults.allowedPassesIds}
                                                isMulti={true}
                                                where={{ type: "pass", status: "active" }}
                                            />

                                        </Col>
                                    }

                                    {form.values.customize?.includes("passes") &&
                                        <Col col={{ xs: 24, md: 12 }}>

                                            <FieldRelationship
                                                form={form}
                                                label={"Retake Passes"}
                                                name='retakePassesIds'
                                                required={false}
                                                model={'Products'}
                                                preload={true}
                                                defaultValue={form.defaults.retakePassesIds}
                                                isMulti={true}
                                                where={{ type: "pass", status: "active" }}
                                            />

                                        </Col>
                                    }

                                    {form.values.customize?.includes("limits") &&
                                        <Col col={{ xs: 24, md: 12 }}>

                                            <FieldNumber
                                                form={form}
                                                label={"Limit Leads"}
                                                name='limitLeads'
                                                required={false}
                                                defaultValue={form.defaults.limitLeads}
                                                decimalScale={0}
                                            />

                                        </Col>
                                    }

                                    {form.values.customize?.includes("limits") &&
                                        <Col col={{ xs: 24, md: 12 }}>

                                            <FieldNumber
                                                form={form}
                                                label={"Limit Follows"}
                                                name='limitFollows'
                                                required={false}
                                                defaultValue={form.defaults.limitFollows}
                                                decimalScale={0}
                                            />

                                        </Col>
                                    }

                                    {form.values.customize?.includes("details") &&
                                        <Col col={{ xs: 24, md: 24 }}>

                                            <FieldRich
                                                form={form}
                                                label={"Details"}
                                                name='details'
                                                required={false}
                                                defaultValue={form.defaults.details}
                                            />

                                        </Col>
                                    }

                                </>
                            }

                        </Row>

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        {/* status */}

                    </Col>

                </Row>


                <Line />

                <ButtonRow>
                    <Button
                        label={"Save"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>

    ), [props, process.loading, form.hash]);

}

export default ClassesCreateEditForm;
