import React, { useCallback, useMemo, useState } from 'react';

import styles from "./AttendeeCard.module.scss";
import { Button, ButtonGroup, ButtonRow, Col, Link, ModelLink, Row, Status, WhiteSpace } from '@dex/bubl-dash';
import AttendeesApi from '../../views/Attendees/AttendeesApi';
import { FiMinusCircle } from "react-icons/fi";

const AttendeeCard: React.FC<AttendeeCardProps> = (props) => {

    const { data } = props;

    const [attendee, setAttendee] = useState(props.attendee);

    const update = AttendeesApi.update(props.attendee.id, "updateStatus");

    const setStatus = useCallback((status) => {

        update.run({
            data: { status: status },
            onComplete: (response) => {
                setAttendee(response);
            }
        })

    }, []);

    const status = attendee?.status;

    return useMemo(() => (

        <>
            {attendee &&
                <div className={styles.card}>

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, sm: 12, md: 8 }} justify='center'>

                            <Link
                                onClick={setStatus.bind(null, "removed")}
                                className={styles.remove}
                                loading={update.loading}
                                title={"Remove Attendee"}
                                confirm={{
                                    title: "Remove " + attendee.user.fullName,
                                    message: "Are you sure you want to remove this attendee?",
                                }}
                            >
                                <FiMinusCircle />
                            </Link>

                            <span className={styles.user}>
                                <ModelLink data={attendee.user} />
                            </span>

                        </Col>

                        <Col col={{ xs: 24, sm: 12, md: 8 }} justify='center'>

                            {!attendee.hasPass &&
                                <Status prefix={"attendees.pass"} status={"no-pass"} />
                            }

                            {attendee.hasPass && attendee.isRetake &&
                                <Status prefix={"attendees.pass"} status={"retake"} />
                            }

                            {attendee.hasPass && !attendee.isRetake &&
                                <Status prefix={"attendees.pass"} status={"has-pass"} />
                            }

                            {attendee.hasPass &&
                                <>
                                    <WhiteSpace /> <WhiteSpace />
                                    <ModelLink data={attendee.pass} />
                                </>
                            }

                        </Col>

                        <Col col={{ xs: 24, sm: 24, md: 8 }} className={styles.actions}>

                            <ButtonGroup
                                condense={false}
                            >
                                <Button
                                    size={"small"}
                                    label={"Attendding"}
                                    type={status === "attending" ? "primary" : "light"}
                                    loading={update.loading}
                                    onClick={setStatus.bind(null, "attending")}
                                />
                                <Button
                                    size={"small"}
                                    label={"Attended"}
                                    type={status === "attended" ? "primary" : "light"}
                                    loading={update.loading}
                                    onClick={setStatus.bind(null, "attended")}
                                />
                                <Button
                                    size={"small"}
                                    label={"No Show"}
                                    type={status === "noshow" ? "primary" : "light"}
                                    loading={update.loading}
                                    onClick={setStatus.bind(null, "noshow")}
                                />
                            </ButtonGroup>

                        </Col>

                    </Row>

                </div>
            }
        </>

    ), [data, attendee, update.hash]);

}

interface AttendeeCardProps {
    [key: string]: any,
}

export default AttendeeCard;