import React, { useCallback, useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Button from '@dex/bubl-dash/src/lib/components/Button/Button';
import { FieldRelationship, FieldWrap, useForm } from '@dex/bubl-dash';
import ClassesApi from '../ClassesApi';


const ClassesAttendeesAddForm: React.FC<any> = (props: any) => {

    const { classId, handleRefresh } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    const form = useForm({});

    const process = ClassesApi.update(classId, "addAttendee");

    const handleSubmit = useCallback((values) => {

        process.run({
            data: values,
            onComplete: () => {

                form.reset({ userId: "" });

                handleRefresh();

            }
        });

    }, [process.run, classId, handleRefresh]);


    return useMemo(() => (

        <Form
            form={form}
            onSubmit={handleSubmit}
            loading={process.loading}
        >

            <Row gutter={8} edge={true}>

                <Col grow={1}>

                    <FieldRelationship
                        form={form}
                        name='userId'
                        required={true}
                        model={'Users'}
                        preload={true}
                        defaultValue={form.defaults.userId}
                        placeholder={"Select Attendee To Add"}
                    />

                </Col>

                <Col shrink={1}>

                    <FieldWrap>
                        <Button
                            label={"Submit"}
                            loading={process.loading}
                            size='regular'
                        />
                    </FieldWrap>

                </Col>

            </Row>

        </Form>

    ), [classId, form.hash, process.hash]);

}

export default ClassesAttendeesAddForm;
