import React, { useEffect, useMemo, useState } from 'react';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import UsersApi from '@dex/bubl-dash/src/lib/views/Users/UsersApi';
import { Space } from '@dex/bubl-dash';
import ClassAttendedCard from '../../../elements/ClassAttendedCard/ClassAttendedCard';
import UserPassCard from '../../../elements/UserPassCard/UserPassCard';

const UserTimeline: React.FC<any> = (props: any) => {

    const { data } = props;

    const [key, setKey] = useState(0);

    const timeline = UsersApi.format(data.id, "getTimeline");

    useEffect(() => {

        timeline.run();

    }, [key])

    return useMemo(() => (

        <Panel
            heading={"Timeline"}
        >

            {timeline.data && timeline.data.length > 0 &&
                <>
                    {timeline.data.map((item, key) => (
                        <React.Fragment key={item.id}>
                            {item.modelName === "Attendees" &&
                                <ClassAttendedCard
                                    attendce={item}
                                    showPass={true}
                                />
                            }

                            {item.modelName === "UserPasses" &&
                                <>
                                    <UserPassCard
                                        pass={item}
                                    />
                                </>
                            }
                        </React.Fragment>
                    ))}
                </>
            }

        </Panel>

    ), [data, key, timeline.hash]);

}

export default UserTimeline;
