import LevelsIndex from './LevelsIndex';
import LevelsCreate from './LevelsCreate';
import LevelsSettings from './LevelsSettings';
import LevelsSingle from './LevelsSingle';

export default function (app) {

    const settings = LevelsSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: settings.title,
        addPath: settings.path + "/create",
        addCaps: settings.createCaps,
        priority: 11,
        items: [
            {
                label: "All Levels",
                path: settings.path,
                caps: settings.indexCaps,
            },
        ],
    });

    LevelsIndex(app, settings);

    LevelsCreate(app, settings);

    LevelsSingle(app, settings);

}