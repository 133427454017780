import React, { useCallback, useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { FieldRadio, FieldUpload } from '@dex/bubl-dash';

const StylesCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"Style Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldText
                            form={form}
                            label={"Title"}
                            name='title'
                            required={true}
                            defaultValue={form.defaults.title}
                            transform={'capitalize'}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldRadio
                            form={form}
                            label={"Status"}
                            name='status'
                            required={true}
                            defaultValue={form.defaults.status}
                            options={[
                                { label: "Active", value: "active" },
                                { label: "Inactive", value: "inactive" },
                            ]}
                        />

                    </Col>

                </Row>

                <Line />

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldUpload
                            form={form}
                            folder={'admin-media'}
                            label={"Class Icon"}
                            name={'iconClass'}
                            required={false}
                            defaultValue={form.defaults.iconClass}
                            accept={"image/*"}
                            limit={1}

                        />

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldUpload
                            form={form}
                            folder={'admin-media'}
                            label={"Social Icon"}
                            name={'iconSocial'}
                            required={false}
                            defaultValue={form.defaults.iconSocial}
                            accept={"image/*"}
                            limit={1}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldUpload
                            form={form}
                            folder={'admin-media'}
                            label={"Practice Icon"}
                            name={'iconPractice'}
                            required={false}
                            defaultValue={form.defaults.iconPractice}
                            accept={"image/*"}
                            limit={1}
                        />

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Save"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default StylesCreateEditForm;
