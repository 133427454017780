import ProductsIndex from './ProductsIndex';
import ProductsCreate from './ProductsCreate';
import ProductsSettings from './ProductsSettings';
import ProductsSingle from './ProductsSingle';

export default function (app) {

    const settings = ProductsSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: settings.title,
        addPath: settings.path + "/create",
        addCaps: settings.createCaps,
        priority: 13,
        items: [
            {
                label: "All Products",
                path: settings.path,
                caps: settings.indexCaps,
            },
            {
                label: "Passes",
                path: settings.path + "/type/pass",
                caps: settings.createCaps
            },
            {
                label: "Tickets",
                path: settings.path + "/type/ticket",
                caps: settings.createCaps
            },
            {
                label: "Merch",
                path: settings.path + "/type/merch",
                caps: settings.createCaps
            },
        ],
    });

    ProductsIndex(app, settings);

    ProductsCreate(app, settings);

    ProductsSingle(app, settings);

}