import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import UserPassesClassesAttended from './UserPassesClassesAttended';
import { Space } from '@dex/bubl-dash';

const UserPassesSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                col={{ xs: 24 }}
            >

                <Panel
                    heading={"User Passes Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "User",
                                key: "user",
                                format: "ModelLink"
                            },
                            {
                                label: "Product",
                                key: "product",
                                format: "ModelLink"
                            },
                            data.type === "order" && {
                                label: "Order",
                                key: "order",
                                format: "ModelLink",
                            },
                            data.type === "description" && {
                                label: "Description",
                                key: "description",
                                format: "nl2br",
                                hideIfEmpty: true
                            },
                        ].filter(Boolean)}
                        columnB={[
                            {
                                label: "No of Classes",
                                key: "noOfClasses",
                                format: "number",
                                hideIfEmpty: true
                            },
                            {
                                label: "No of Socials",
                                key: "noOfSocials",
                                format: "number",
                                hideIfEmpty: true
                            },
                        ].filter(Boolean)}
                        columnC={[
                            {
                                label: "Classes Left",
                                key: "leftClasses",
                                format: "number",
                                hideIfEmpty: true
                            },
                            {
                                label: "Socials Left",
                                key: "leftSocials",
                                format: "number",
                                hideIfEmpty: true
                            },
                        ].filter(Boolean)}
                    />

                </Panel>

                <Space />
                <UserPassesClassesAttended data={data} />

            </Col>

        </Row>

    ), [data]);

}

export default UserPassesSingleView;
