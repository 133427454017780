import IndexPage from '@dex/bubl-dash/src/lib/pages/IndexPage';
import OrdersCreateEditForm from "./Components/OrdersCreateEditForm";
import OrdersApi from "./OrdersApi";

export default function (app, settings) {

    app.addRoute({
        name: settings.key + "New",
        zone: settings.zone,
        path: settings.key + "/create",
        caps: settings.createCaps,
        component: IndexPage,
        componentProps: {
            header: {
                getTitle: () => "Add New",
                getButtons: settings.indexButtons,
                getTabs: settings.indexTabs,
                getTabCounts: settings.indexTabCounts,
            },
            create: {
                form: OrdersCreateEditForm,
                getCreate: OrdersApi.create,
                getClone: OrdersApi.getOne,
                defaults: { items: [] },
                successMessage: "Order Saved",
                successRouteName: settings.key + "View",
                idKey: settings.idKey,
            },
        },
    });

}