import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';

const StocksSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                col={{ xs: 24 }}
            >

                <Panel
                    heading={"Stock Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "SKU",
                                key: "sku",
                            },
                            {
                                label: "Title",
                                key: "title",
                                format: "titleCase"
                            },
                        ]}
                        columnB={[
                            {
                                label: "Low Stock Threshold",
                                key: "lowStockThreshold",
                                format: "number"
                            },
                        ]}
                        columnC={[

                        ]}
                    />

                </Panel>

            </Col>

        </Row>

    ), [data]);

}

export default StocksSingleView;
