import React from 'react';

import BublDash from '@dex/bubl-dash/src/lib/Dash';
import Media from '@dex/bubl-dash/src/lib/views/Media/Media';
import Users from '@dex/bubl-dash/src/lib/views/Users/Users';
import Defaults from '@dex/bubl-dash/src/lib/views/Defaults';
import Wrapper from '@dex/bubl-dash/src/lib/Wrapper';

import Activities from './views/Activities/Activities';
import Levels from './views/Levels/Levels';
import Classes from './views/Classes/Classes';
import Attendees from './views/Attendees/Attendees';
import Styles from './views/Styles/Styles';

import Products from './views/Products/Products';
import Orders from './views/Orders/Orders';
import UserPasses from './views/UserPasses/UserPasses';
import Stocks from './views/Stocks/Stocks';
import StockTransfers from './views/StockTransfers/StockTransfers';

import Pages from './views/Pages/Pages';

import './styles/App.scss';

import DiscoBlack from './assets/Disco-Black.svg';
import DiscoWhite from './assets/Disco-White.svg';

import ShopBlack from './assets/Shop-Black.svg';
import ShopWhite from './assets/Shop-White.svg';

import UsersMods from './views/Users/UsersMods';
import Dashboard from './views/Dashboard/Dashboard';

const app = new BublDash({
    name: "STUDIO",
    //icon: "assets/logo.png",
    defaultLang: "en",
    strings: {
        en: [
            require('./strings/orders.json'),
            require('./strings/classes.json'),
            require('./strings/attendees.json'),
        ]
    },
    accessRoles: [
        'admin',
        'super',
        'attendanceManager',
        'studioManager',
        'activityManager',
    ], //must be one of these roles in order to sign in
    userRoles: [
        {
            key: 'attendanceManager',
            label: 'Attendance Manager'
        },
        {
            key: 'studioManager',
            label: 'Studio Manager'
        },
        {
            key: 'activityManager',
            label: 'Activity Manager'
        },
        {
            key: 'admin',
            label: 'Admin',
            group: {
                key: "admin",
                label: "Admin"
            }
        },
        {
            key: 'super',
            label: 'Super',
            group: {
                key: "admin",
                label: "Admin"
            }
        },
        {
            key: 'user',
            label: 'User'
        },
    ],
    env: process.env.REACT_APP_ENV || "development", //set env by modifying the output sent by express server
});

app.addRoute({
    name: "home",
    path: "/",
    component: () => <Dashboard />,
    public: false,
    sidebar: false,
    componentProps: {},
    caps: [],
});

app.addZone({
    key: "studio",
    label: "Studio",
    priority: 10,
    icon: DiscoBlack,
    iconActive: DiscoWhite,
});

app.addZone({
    key: "commerce",
    label: "Commerce",
    priority: 15,
    icon: ShopBlack,
    iconActive: ShopWhite,
});

app.addZone({
    key: "content",
    label: "Content",
    priority: 15,
    icon: ShopBlack,
    iconActive: ShopWhite,
});


//Add New Models Here
const extensions: any = [
    UsersMods,
    Defaults,
    Users,
    Media,
    Activities,
    Levels,
    Classes,
    Attendees,
    Styles,
    Products,
    Orders,
    UserPasses,
    Stocks,
    StockTransfers,
    // Pages
];

extensions.map(extension => {

    extension(app);

    return null;

});

const App: React.FC = () => {

    return (
        <Wrapper>
            {app.render()}
        </Wrapper>
    )

}

export default App;