import React, { useCallback, useMemo, useState } from 'react';

import styles from "./UserPassCard.module.scss";
import { Col, ModelLink, Row, Status, WhiteSpace } from '@dex/bubl-dash';

const UserPassCard: React.FC<UserPassCardProps> = (props) => {

    const { pass } = props;

    return useMemo(() => (

        <div className={styles.card}>

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, sm: 12, md: 8 }} justify='center'>

                    <span className={styles.link}>
                        <ModelLink data={{ id: pass?.id, title: pass?.id }} modelName="UserPasses" />
                    </span>

                    <br />

                    <span className={styles.title}>
                        {pass.title}
                    </span>

                </Col>

                <Col col={{ xs: 24, sm: 12, md: 8 }} justify='center'>

                    <span className={styles.left}>
                        {pass.leftClasses} Classes Left
                    </span>
                    <WhiteSpace />/<WhiteSpace />
                    <span className={styles.left}>
                        {pass.leftSocials} Socials Left
                    </span>

                </Col>

                <Col col={{ xs: 24, sm: 12, md: 8 }} justify='center' className={styles.actions}>

                    <Status prefix={"pass.status"} status={pass.status} />

                </Col>

            </Row>

        </div>

    ), [pass]);

}

interface UserPassCardProps {
    [key: string]: any,
}

export default UserPassCard;