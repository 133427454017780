import UserPassesIndex from './UserPassesIndex';
import UserPassesCreate from './UserPassesCreate';
import UserPassesSettings from './UserPassesSettings';
import UserPassesSingle from './UserPassesSingle';

export default function (app) {

    const settings = UserPassesSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: settings.title,
        addPath: settings.path + "/create",
        addCaps: settings.createCaps,
        priority: 13,
        items: [
            {
                label: "All User Passes",
                path: settings.path,
                caps: settings.indexCaps,
            },
        ],
    });

    UserPassesIndex(app, settings);

    UserPassesCreate(app, settings);

    UserPassesSingle(app, settings);

}