import React, { useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import { Button, Line, ModelLink, Seperator, Space } from '@dex/bubl-dash';
import ClassesAttendees from './ClassesAttendees';
import { clone } from '@dex/bubl-helpers';
import { dayDateTime } from '../../../functions/formats';

const ClassesSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    const level = clone(data.level);

    if (data.customize?.includes("passes")) {
        level.allowedPasses = data.allowedPasses;
        level.retakePasses = data.retakePasses;
    }

    if (data.customize?.includes("limits")) {
        level.limitLeads = data.limitLeads;
        level.limitFollows = data.limitFollows;
    }

    if (data.customize?.includes("details")) {
        level.details = data.details;
    }

    const [details, showDetails] = useState(false);

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                col={{ xs: 24 }}
            >

                <Panel
                    heading={"Class Details"}
                    actions={(
                        <>
                            <Button
                                label={details ? "Hide More Details" : "Show More Details"}
                                size={"small"}
                                type={"faded"}
                                onClick={showDetails.bind(null, !details)}
                            />
                        </>
                    )}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Style / Level",
                                key: "level",
                                render: () => (
                                    <>
                                        <ModelLink data={data.style} /> / <ModelLink data={data.level} />
                                    </>
                                )
                            },
                        ]}
                        columnB={[
                            {
                                label: "Date Time",
                                key: "dateTime",
                                render: dayDateTime,
                            },
                        ]}
                        columnC={[

                            {
                                label: "Duration",
                                key: "duration",
                                format: "number",
                                suffix: " mins"
                            }
                        ]}
                    />

                    {details &&
                        <>
                            <Line />

                            <MetaTable
                                data={level}
                                columnA={[
                                    {
                                        label: "Allowed Passes",
                                        key: "allowedPasses",
                                        format: "ModelLink",
                                        join: <br />
                                    },
                                    {
                                        label: "Retake Passes",
                                        key: "retakePasses",
                                        format: "ModelLink",
                                        join: <br />
                                    },
                                ]}
                                columnB={[
                                    {
                                        label: "Limit Leads",
                                        key: "limitLeads",
                                    },
                                    {
                                        label: "Limit Follows",
                                        key: "limitFollows",
                                    },
                                ]}
                                columnC={[
                                    {
                                        label: "Details",
                                        key: "details",
                                        format: "html"
                                    },
                                ]}
                            />
                        </>
                    }

                    {data.isCancelled === true &&
                        <>
                            <Seperator
                                heading="Cancellation Details"
                                top="auto"
                                bottom="auto"
                            />

                            <MetaTable
                                data={data}
                                columnA={[
                                    {
                                        label: "Cancellation Reason",
                                        key: "cancelClassReason",
                                        format: "nl2br",
                                    },

                                ]}
                                columnB={[
                                    {
                                        label: "Cancellation On",
                                        key: "isCancelledOn",
                                        format: "OnAndBy",
                                    },
                                ]}
                            />
                        </>
                    }

                </Panel>

                <Space />

                <ClassesAttendees data={data} />

            </Col>

        </Row>

    ), [data, details]);

}

export default ClassesSingleView;
