import React, { useCallback, useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { FieldDate, FieldNumber, FieldRadio, FieldRich } from '@dex/bubl-dash';
import { dateDay } from '@dex/bubl-helpers';
import moment from 'moment';

const StocksCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit, mode } = props;



    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"Stock Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 10 }}>

                        <FieldText
                            form={form}
                            label={"SKU"}
                            name='sku'
                            required={true}
                            defaultValue={form.defaults.sku}
                            disabled={mode === "edit"}
                            transform={"slug"}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 10 }}>

                        <FieldText
                            form={form}
                            label={"Title"}
                            name='title'
                            required={true}
                            defaultValue={form.defaults.title}
                            transform={'capitalize'}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 10 }}>

                        <FieldNumber
                            form={form}
                            label={"Low Stock Threshold"}
                            name='lowStockThreshold'
                            defaultValue={form.defaults.lowStockThreshold}
                            decimalScale={0}
                        />

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Save"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default StocksCreateEditForm;
