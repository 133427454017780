import ActivitiesIndex from './ActivitiesIndex';
import ActivitiesCreate from './ActivitiesCreate';
import ActivitiesSettings from './ActivitiesSettings';
import ActivitiesSingle from './ActivitiesSingle';

export default function (app) {

    const settings = ActivitiesSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    // app.addMenu({
    //     key: settings.key,
    //     zone: settings.zone,
    //     label: settings.title,
    //     addPath: settings.path + "/create",
    //     addCaps: settings.createCaps,
    //     priority: 13,
    //     items: [
    //         {
    //             label: "All Activities",
    //             path: settings.path,
    //             caps: settings.indexCaps,
    //         },
    //     ],
    // });

    // ActivitiesIndex(app, settings);

    // ActivitiesCreate(app, settings);

    // ActivitiesSingle(app, settings);

}