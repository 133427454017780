import React from 'react';
import { titleCase, get } from "@dex/bubl-helpers";
import StockTransfersApi from './StockTransfersApi';


let settings;

export default settings = {
    key: 'stocktransfers',
    zone: 'commerce',
    path: '/stocktransfers',
    title: "Stock Transfers",
    endpoint: StockTransfersApi.endpoint,
    modelName: 'StockTransfers',
    idKey: 'id',
    primaryKey: 'id',
    indexCaps: ["viewStockTransfers"],
    viewCaps: ["viewStockTransfers"],
    createCaps: ["createStockTransfers"],
    actions: ['trash'],
    indexTitle: (routeName, params, user) => {

        let title: string = settings.title;

        if (params && params.name) title = title + " / " + titleCase(params.name);

        return title;

    },
    indexTabs: (routeName, params, user) => {

        return [
            {
                label: "All",
                path: settings.path
            },
            {
                label: "Add New",
                path: settings.path + "/create"
            },
        ]

    },
    indexButtons: (routeName, params, user) => {

        return [
            {
                label: "Add New",
                path: settings.path + '/create',
                caps: settings.createCaps
            }
        ]

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: StockTransfersApi.get,
    indexFetchParams: (routeName, params, user) => {

        const filters: any = {
            where: {},
            include: [
                {
                    relation: 'stock',
                    preset: "link"
                },
            ],
            order: ['created.date DESC'], limit: 10, skip: 0
        };

        if (params && params.status) filters.where.status = { inq: [params.status] };

        return filters;

    },
    indexQuickSearch: (routeName, params, user) => {

        const fields = ['id', 'title'];

        return fields;

    },
    indexSearchFields: (routeName, params, user) => {

        const fields = [

            {
                label: "Title",
                key: "title",
                type: "text",
            },
            {
                label: "Stock",
                key: "stockId",
                type: "relationship",
                model: "Stocks"
            },
            {
                label: "Action",
                key: "action",
                type: "choice",
                options: [
                    { value: "add", label: "Add" },
                    { value: "remove", label: "Remove" },
                ]
            },
            {
                label: "Created Date",
                key: "created.date",
                type: "date",
            },
        ];

        return fields;

    },

    indexColumns: (routeName, params, user) => {

        const columns = [
            {
                label: "Stock Transfers ID",
                key: "this",
                format: "ModelLink",
            },
            {
                label: "Stock",
                key: "stock",
                format: "ModelLink"
            },
            {
                label: "Action",
                key: "action",
                format: "titleCase"
            },
            {
                label: "Created Date",
                key: "created",
                format: "OnAndBy",
                sort: "created.date",
                alignEnd: true
            },
            {
                label: "Actions",
                key: "actions",
                alignEnd: true,
                format: "actions",
                api: StockTransfersApi,
                settings: settings,
            }
        ];

        return columns;

    },
    singleFetch: StockTransfersApi.getOne,
    singleFetchParams: (routeName, params, user, fetch) => {

        const filters = {
            include: [
                {
                    relation: 'stock',
                    preset: "link"
                },
            ]
        };

        return filters;

    },
    singleTitle: (routeName, params, user, fetch) => {

        let title: string = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');

        if (error) title = "Error";
        else if (data) title = data[settings.primaryKey];
        else if (complete && !data) title = "Not Found";

        return title;

    },
    singleTabs: (routeName, params, user, fetch) => {

        const path = settings.path + "/" + params.id;

        return [
            {
                label: "View",
                path: path + "/view",
                caps: settings.viewCaps
            },
            {
                label: "Edit",
                path: path + "/edit",
                caps: settings.createCaps
            },
        ]

    },
    singleButtons: (routeName, params, user, fetch) => {

        return [
        ].filter(Boolean);

    },
};