import React, { useMemo } from 'react';
import { DataTable, DataTableCell, InputTableRow, Seperator, useApp } from '@dex/bubl-dash';
import { formatNum, upperCase } from '@dex/bubl-helpers';
import ModelLink from '@dex/bubl-dash/src/lib/components/ModelLink/ModelLink';

const ProductsSizes: React.FC<any> = (props: any) => {

    const { data } = props;

    const app = useApp();

    const columns = [
        {
            label: "Size",
            key: "size",
        },
        {
            label: "Stock Title",
            key: "stock",
        },
        {
            label: "Stock SKU",
            key: "stock",
        },
        {
            label: "Price",
            key: "price",
            alignEnd: true,
        },
    ];

    return useMemo(() => (

        <>

            <Seperator
                heading={"Sizes"}
                top={"auto"}
                bottom={"auto"}
            />

            <DataTable
                data={null}
                columns={columns}
                className={"mini-table invoice-table"}
            >

                {data.sizes.map((row: any, index: number) => (
                    <InputTableRow className={"invoice-line-item"} key={index}>

                        <DataTableCell style={{ width: "80px" }} className={"invoice-line-desc"}>
                            {upperCase(row.size)}
                        </DataTableCell>

                        <DataTableCell className={"invoice-line-value"}>
                            <ModelLink data={{ id: row.stockId, title: row.stockTitle }} modelName={"Stocks"} />
                        </DataTableCell>

                        <DataTableCell className={"invoice-line-value"}>
                            {row.stockId}
                        </DataTableCell>

                        <DataTableCell alignEnd className={"invoice-line-quantity"}>
                            {formatNum(row.price, 2, "RM ")}
                        </DataTableCell>

                    </InputTableRow>
                ))}


            </DataTable>

        </>

    ), [data]);

}


export default ProductsSizes;