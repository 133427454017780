import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import { FieldNumber, FieldRelationship, FieldRepeater, FieldSelect } from '@dex/bubl-dash';
import { loop, titleCase } from '@dex/bubl-helpers';

const OrdersFormItems: React.FC<any> = (props: any) => {

    const { form, products } = props;

    const options = products.map((product) => {
        return { value: product.id, label: titleCase(product.type) + " | " + product.title };
    })

    return (

        <FieldRepeater
            form={form}
            name={'items'}
            required={true}
            addButtonLabel={"Add Items"}
            defaultValues={{ quantity: 1 }}
        >

            {form.values.items.map((row, x) => (

                <React.Fragment key={form.key + "-" + x}>

                    <OrdersFormItemsRow
                        form={form}
                        row={row}
                        index={x}
                        products={products}
                        options={options}
                    />

                </React.Fragment>

            ))}

        </FieldRepeater>

    );

}


const OrdersFormItemsRow: React.FC<any> = (props: any) => {

    const { form, row, index, options, products } = props;

    const product = useMemo(() => {

        let product: any = {};

        loop(products, (item) => {
            if (item.id === row.productId) product = item;
        })

        return product;

    }, [row.productId])

    const sizes = (product.sizes || []).map((size) => {

        return { value: size.size, label: size.size };

    });

    return (

        <>


            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 15 }}>

                    <FieldSelect
                        form={form}
                        label={"Product"}
                        name={`items[${index}].productId`}
                        model={'Products'}
                        defaultValue={row.productId}
                        required={true}
                        options={options}
                    />

                </Col>

                {row.productId &&
                    <>

                        {product.type === "merch" &&
                            <Col col={{ xs: 24, md: 5 }}>

                                <FieldSelect
                                    form={form}
                                    label={"Size"}
                                    name={`items[${index}].size`}
                                    defaultValue={row.size}
                                    required={true}
                                    options={sizes}
                                />

                            </Col>
                        }

                        {product.type === "merch" &&
                            <Col col={{ xs: 24, md: 4 }}>

                                <FieldNumber
                                    form={form}
                                    label={"Quantity"}
                                    name={`items[${index}].quantity`}
                                    defaultValue={row.quantity}
                                    required={true}
                                    decimalScale={0}
                                />

                            </Col>
                        }

                    </>

                }

            </Row>
        </>
    )

}

export default OrdersFormItems;
