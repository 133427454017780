import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { FieldCheckbox, FieldNumber, FieldRadio, FieldRelationship, FieldRich, Seperator } from '@dex/bubl-dash';

const LevelsCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"Level Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >


                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 16 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldText
                                    form={form}
                                    label={"Title"}
                                    name='title'
                                    required={true}
                                    defaultValue={form.defaults.title}
                                    transform={'capitalize'}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldText
                                    form={form}
                                    label={"Short Title"}
                                    name='shortTitle'
                                    required={true}
                                    defaultValue={form.defaults.shortTitle}
                                    transform={'capitalize'}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 24 }}>

                                <FieldRelationship
                                    form={form}
                                    label={"Style"}
                                    name='styleId'
                                    required={true}
                                    model={'Styles'}
                                    preload={true}
                                    defaultValue={form.defaults.styleId}
                                />

                            </Col>

                        </Row>

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24 }}>

                                <FieldRadio
                                    form={form}
                                    label={"Status"}
                                    name='status'
                                    required={true}
                                    defaultValue={form.defaults.status}
                                    options={[
                                        { label: "Active", value: "active" },
                                        { label: "Inactive", value: "inactive" },
                                    ]}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldCheckbox
                                    form={form}
                                    label={"Enabled For"}
                                    name='enabledFor'
                                    defaultValue={form.defaults.enabledFor}
                                    options={[
                                        { label: "Class", value: "class" },
                                        { label: "Social", value: "social" },
                                        { label: "Practice", value: "practice" },
                                    ]}
                                />

                            </Col>

                        </Row>

                    </Col>

                </Row>

                {form.values?.enabledFor?.includes("class") &&
                    <ClassDefaults
                        form={form}
                    />
                }

                {form.values?.enabledFor?.includes("social") &&
                    <SocialDefaults
                        form={form}
                    />
                }

                {form.values?.enabledFor?.includes("practice") &&
                    <PracticeDefaults
                        form={form}
                    />
                }

                <Line />

                <ButtonRow>
                    <Button
                        label={"Save"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}



const ClassDefaults: React.FC<any> = (props: any) => {

    const { form } = props;

    return (


        <>
            <Seperator
                top={"auto"}
                bottom={"auto"}
                heading={"Class Defaults"}
            />

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 16 }}>

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldNumber
                                form={form}
                                label={"Class Duration"}
                                name='duration'
                                suffix={" mins"}
                                required={true}
                                defaultValue={form.defaults.duration}
                                decimalScale={0}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 6 }}>

                            <FieldNumber
                                form={form}
                                label={"Limit Leads"}
                                name='limitLeads'
                                required={false}
                                defaultValue={form.defaults.limitLeads}
                                decimalScale={0}
                                placeholder='#'
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 6 }}>

                            <FieldNumber
                                form={form}
                                label={"Limit Follows"}
                                name='limitFollows'
                                required={false}
                                defaultValue={form.defaults.limitFollows}
                                decimalScale={0}
                                placeholder='#'
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldRich
                                form={form}
                                label={"Details"}
                                name='details'
                                required={false}
                                defaultValue={form.defaults.details}
                            />

                        </Col>

                    </Row>

                </Col>

                <Col col={{ xs: 24, md: 8 }}>

                    <Row gutter={8} edge>

                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldRelationship
                                form={form}
                                label={"Allowed Passes"}
                                name='allowedPassesIds'
                                model={'Products'}
                                preload={true}
                                defaultValue={form.defaults.allowedPassesIds}
                                isMulti={true}
                                where={{ type: "pass", status: "active" }}
                                required={true}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldRelationship
                                form={form}
                                label={"Retake Passes"}
                                name='retakePassesIds'
                                required={false}
                                model={'Products'}
                                preload={true}
                                defaultValue={form.defaults.retakePassesIds}
                                isMulti={true}
                                where={{ type: "pass", status: "active" }}
                            />

                        </Col>

                    </Row>

                </Col>

            </Row>

        </>

    );

}

const PracticeDefaults: React.FC<any> = (props: any) => {

    const { form } = props;

    return (

        <>

            <Seperator
                heading={"Practice Defaults"}
                top={"auto"}
                bottom={"auto"}
            />

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 16 }}>

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldNumber
                                form={form}
                                label={"Practice Duration"}
                                name='durationPractice'
                                suffix={" mins"}
                                defaultValue={form.defaults.durationPractice}
                                decimalScale={0}
                                required={true}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldNumber
                                form={form}
                                label={"Limit Attendees"}
                                name='limitAttendeesPractice'
                                required={false}
                                defaultValue={form.defaults.limitAttendeesPractice}
                                decimalScale={0}
                                placeholder='#'
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldRich
                                form={form}
                                label={"Details"}
                                name='detailsPractice'
                                required={false}
                                defaultValue={form.defaults.detailsPractice}
                            />

                        </Col>

                    </Row>


                </Col>

                <Col col={{ xs: 24, md: 8 }}>

                    <FieldRelationship
                        form={form}
                        label={"Allowed Passes"}
                        name='allowedPassesIdsPractice'
                        model={'Products'}
                        preload={true}
                        defaultValue={form.defaults.allowedPassesIdsPractice}
                        isMulti={true}
                        where={{ type: "pass", status: "active" }}
                        required={true}
                    />

                </Col>


            </Row>

        </>

    );

}

const SocialDefaults: React.FC<any> = (props: any) => {

    const { form } = props;

    return (

        <>

            <Seperator
                heading={"Social Defaults"}
                top={"auto"}
                bottom={"auto"}
            />

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 16 }}>

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldNumber
                                form={form}
                                label={"Social Duration"}
                                name='durationSocial'
                                suffix={" mins"}
                                defaultValue={form.defaults.durationSocial}
                                decimalScale={0}
                                required={true}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldNumber
                                form={form}
                                label={"Limit Attendees"}
                                name='limitAttendeesSocial'
                                required={false}
                                defaultValue={form.defaults.limitAttendeesSocial}
                                decimalScale={0}
                                placeholder='#'
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldRich
                                form={form}
                                label={"Details"}
                                name='detailsSocial'
                                required={false}
                                defaultValue={form.defaults.detailsSocial}
                            />

                        </Col>

                    </Row>


                </Col>

                <Col col={{ xs: 24, md: 8 }}>

                    <FieldRelationship
                        form={form}
                        label={"Allowed Passes"}
                        name='allowedPassesIdsSocial'
                        model={'Products'}
                        preload={true}
                        defaultValue={form.defaults.allowedPassesIdsSocial}
                        isMulti={true}
                        where={{ type: "pass", status: "active" }}
                        required={true}
                    />

                </Col>


            </Row>

        </>

    );

}


export default LevelsCreateEditForm;
