import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import OrdersLineItems from './OrdersLineItems';
import { Space } from '@dex/bubl-dash';
import OrderStages from '../Stages/OrderStages';

const OrdersSingleView: React.FC<any> = (props: any) => {

    const { data, hash } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                col={{ xs: 24 }}
            >

                <Panel
                    heading={"Order Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "User",
                                key: "user",
                                format: "ModelLink"
                            },
                        ]}
                        columnB={[

                        ]}
                        columnC={[
                            {
                                label: "Created",
                                key: "created",
                                format: "OnAndBy"
                            }

                        ]}
                    />

                    <Space />

                    <OrdersLineItems
                        data={data}
                    />

                </Panel>

            </Col>

            <Col
                col={{ xs: 24 }}
            >

                <OrderStages
                    key={hash}
                    data={data}
                />

            </Col>

        </Row>

    ), [hash]);

}

export default OrdersSingleView;
