import React, { useMemo } from 'react';
import UsersInfoPanel from './Components/UsersInfoPanel';
import { Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import UserTimeline from './Components/UserTimeline';

const UsersSingleViewMain: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <>

            {/* <Space />

            <UsersInfoPanel {...props} /> */}

            <Space />

            <UserTimeline data={data} />

        </>

    ), [data, props]);

}

export default UsersSingleViewMain;
