import React, { useEffect, useMemo, useState } from 'react';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import UserPassesApi from '../UserPassesApi';
import { Space } from '@dex/bubl-dash';
import ClassAttendedCard from '../../../elements/ClassAttendedCard/ClassAttendedCard';

const UserPassesClassesAttended: React.FC<any> = (props: any) => {

    const { data } = props;

    const [key, setKey] = useState(0);

    const attended = UserPassesApi.format(data.id, "attended");

    useEffect(() => {

        attended.run();

    }, [key])

    return useMemo(() => (

        <Panel
            heading={"Classes Attended"}
        >

            <Space height={4} />

            {attended.data && attended.data.length > 0 &&
                <>
                    {attended.data.map((item, key) => (
                        <ClassAttendedCard
                            key={item.id}
                            attendce={item}
                        />
                    ))}
                </>
            }

        </Panel>

    ), [data, key, attended.hash]);

}

export default UserPassesClassesAttended;
