import React, { useEffect, useMemo, useState } from 'react';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import ClassesApi from '../ClassesApi';
import ClassesAttendeesAddForm from './ClassesAttendeesAddForm';
import AttendeeCard from '../../../elements/AttendeeCard/AttendeeCard';
import { Space } from '@dex/bubl-dash';

const ClassesAttendees: React.FC<any> = (props: any) => {

    const { data } = props;

    const [key, setKey] = useState(0);

    const attendees = ClassesApi.format(data.id, "attendees");

    useEffect(() => {

        attendees.run();

    }, [key])

    return useMemo(() => (

        <Panel
            heading={"Attendees"}
        >

            <ClassesAttendeesAddForm
                classId={data.id}
                handleRefresh={setKey.bind(null, key + 1)}
            />

            <Space height={4} />

            {attendees.data && attendees.data.length > 0 &&
                <>
                    {attendees.data.map((attendee, key) => (
                        <AttendeeCard
                            key={attendee.id}
                            attendee={attendee}
                            data={data}
                        />
                    ))}
                </>
            }

        </Panel>

    ), [data, key, attendees.hash]);

}

export default ClassesAttendees;
