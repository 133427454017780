import React, { useCallback, useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { FieldDate, FieldRadio, FieldRelationship, FieldRich, FieldTextArea, FieldUpload } from '@dex/bubl-dash';
import { dateDay } from '@dex/bubl-helpers';
import moment from 'moment';

const StockTransfersCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;



    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"Stock Transfers Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 10 }}>

                        <FieldRelationship
                            form={form}
                            label={"Stock"}
                            name='stockId'
                            required={true}
                            model={'Stocks'}
                            preload={true}
                            defaultValue={form.defaults.stockId}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 10 }}>

                        <FieldRadio
                            form={form}
                            label={"Action"}
                            name='action'
                            required={true}
                            defaultValue={form.defaults.action}
                            options={[
                                { label: "Add", value: "add" },
                                { label: "Remove", value: "remove" },
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 14 }}>

                        <FieldTextArea
                            form={form}
                            label={"Notes"}
                            name='notes'
                            required={false}
                            defaultValue={form.defaults.notes}
                        />

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Save"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default StockTransfersCreateEditForm;
