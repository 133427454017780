import React from 'react';
import { titleCase, get } from "@dex/bubl-helpers";
import OrdersApi from './OrdersApi';
import { Status, WhiteSpace } from '@dex/bubl-dash';


let settings;

export default settings = {
    key: 'orders',
    zone: 'commerce',
    path: '/orders',
    title: "Orders",
    endpoint: OrdersApi.endpoint,
    modelName: 'Orders',
    idKey: 'id',
    primaryKey: 'id',
    indexCaps: ["viewOrders"],
    viewCaps: ["viewOrders"],
    createCaps: ["createOrders"],
    actions: ['trash'],
    indexTitle: (routeName, params, user) => {

        let title: string = settings.title;

        if (params && params.name) title = title + " / " + titleCase(params.name);

        return title;

    },
    indexTabs: (routeName, params, user) => {

        return [
            {
                label: "All",
                path: settings.path,
                caps: settings.indexCaps,
            },
            {
                label: "Cart",
                path: settings.path + "/status/cart",
                caps: settings.indexCaps,
            },
            {
                label: "Shipping",
                path: settings.path + "/status/shipping",
                caps: settings.indexCaps,
            },
            {
                label: "Complete",
                path: settings.path + "/status/complete",
                caps: settings.indexCaps,
            },
            {
                label: "Add New",
                path: settings.path + "/create",
                caps: settings.createCaps,
            },
        ]

    },
    indexButtons: (routeName, params, user) => {

        return [
            {
                label: "Add New",
                path: settings.path + '/create',
                caps: settings.createCaps
            }
        ]

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: OrdersApi.get,
    indexFetchParams: (routeName, params, user) => {

        const filters: any = {
            where: {},
            include: [
                {
                    relation: 'user',
                    preset: "link"
                },
            ],
            order: ['created.date DESC'],
            limit: 10,
            skip: 0
        };

        if (params && params.status) filters.where.status = { inq: [params.status] };

        return filters;

    },
    indexQuickSearch: (routeName, params, user) => {

        const fields = ['id'];

        return fields;

    },
    indexSearchFields: (routeName, params, user) => {

        const fields = [

            {
                label: "User",
                key: "userId",
                type: "relationship",
                model: "Users"
            },
            {
                label: "Status",
                key: "status",
                type: "choice",
                options: [
                    { value: "cart", label: "Cart" },
                    { value: "processing", label: "Processing" },
                    { value: "shipped", label: "Shipped" },
                    { value: "completed", label: "Completed" },
                ]
            },
            {
                label: "Payment Status",
                key: "paymentStatus",
                type: "choice",
                options: [
                    { value: "pending", label: "Pending" },
                    { value: "paid", label: "Paid" },
                    { value: "failed", label: "Failed" },
                    { value: "refunded", label: "Refunded" },
                ]
            },
            {
                label: "Shipping Status",
                key: "shippingStatus",
                type: "choice",
                options: [
                    { value: "packing", label: "Packing" },
                    { value: "packed", label: "Packed" },
                    { value: "shipped", label: "Shipped" },
                    { value: "delivered", label: "Delivered" },
                    { value: "returned", label: "Returned" },
                    { value: "cancelled", label: "Cancelled" },
                ]
            },
            {
                label: "Created Date",
                key: "created.date",
                type: "date",
            },
        ];

        return fields;

    },

    indexColumns: (routeName, params, user) => {

        const columns = [
            {
                label: "Order ID",
                key: "this",
                format: "ModelLink",
            },
            {
                label: "User",
                key: "user",
                format: "ModelLink"
            },
            {
                label: "Status",
                key: "status",
                render: (value) => (
                    <>
                        {value && <Status prefix={"orders.status"} status={value} />}
                    </>
                )
            },
            {
                label: "Payment Status",
                key: "paymentStatus",
                render: (value) => (
                    <>
                        {value && <Status prefix={"orders.payment"} status={value} />}
                    </>
                )
            },
            {
                label: "Shipping Status",
                key: "shippingStatus",
                render: (value) => (
                    <>
                        {value && <Status prefix={"orders.shipping"} status={value} />}
                    </>
                )
            },
            {
                label: "Created Date",
                key: "created",
                format: "OnAndBy",
                sort: "created.date",
                alignEnd: true
            },
            {
                label: "Actions",
                key: "actions",
                alignEnd: true,
                format: "actions",
                api: OrdersApi,
                settings: settings,
            }
        ];

        return columns;

    },
    singleFetch: OrdersApi.getOne,
    singleFetchParams: (routeName, params, user, fetch) => {

        const filters = {
            include: [
                {
                    relation: 'user',
                    preset: "link"
                },
            ]
        };

        return filters;

    },
    singleTitle: (routeName, params, user, fetch) => {

        let title: any = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');

        if (error) title = "Error";
        else if (data) title = <>{data[settings.primaryKey]} <WhiteSpace /> <Status status={data.status} /><WhiteSpace /> <Status status={data.paymentStatus} /></>;
        else if (complete && !data) title = "Not Found";

        return title;

    },
    singleTabs: (routeName, params, user, fetch) => {

        const path = settings.path + "/" + params.id;

        return [
            {
                label: "View",
                path: path + "/view",
                caps: settings.viewCaps
            },
            {
                label: "Edit",
                path: path + "/edit",
                caps: settings.createCaps
            },
        ]

    },
    singleButtons: (routeName, params, user, fetch) => {

        return [
        ].filter(Boolean);

    },
};