import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';

const StockTransfersSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                col={{ xs: 24 }}
            >

                <Panel
                    heading={"Stock Transfers Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Stock",
                                key: "stock",
                                format: "ModelLink"
                            },
                            {
                                label: "Action",
                                key: "action",
                                format: "titleCase"
                            },

                        ]}
                        columnB={[

                        ]}
                        columnC={[
                            {
                                label: "Notes",
                                key: "notes",
                                format: "nl2br",
                            },
                        ]}
                    />

                </Panel>

            </Col>

        </Row>

    ), [data]);

}

export default StockTransfersSingleView;
