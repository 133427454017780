import StockTransfersIndex from './StockTransfersIndex';
import StockTransfersCreate from './StockTransfersCreate';
import StockTransfersSettings from './StockTransfersSettings';
import StockTransfersSingle from './StockTransfersSingle';

export default function (app) {

    const settings = StockTransfersSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    // app.addMenu({
    //     key: settings.key,
    //     zone: settings.zone,
    //     label: settings.title,
    //     addPath: settings.path + "/create",
    //     addCaps: settings.createCaps,
    //     priority: 13,
    //     items: [
    //         {
    //             label: "All Stock Transfers",
    //             path: settings.path,
    //             caps: settings.indexCaps,
    //         },
    //     ],
    // });

    StockTransfersIndex(app, settings);

    StockTransfersCreate(app, settings);

    StockTransfersSingle(app, settings);

}