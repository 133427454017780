import React, { useEffect, useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { FieldNumber, FieldRadio, FieldRelationship, FieldRepeater, FieldUpload } from '@dex/bubl-dash';
import ProductsApi from '../../Products/ProductsApi';
import OrdersFormItems from './OrdersFormItems';

const OrdersCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    const products = ProductsApi.get();

    useEffect(() => {

        products.run({
            params: { limit: 1000 }
        });

    }, []);

    console.log(products)

    return useMemo(() => (

        <Panel
            heading={"Order Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 10 }}>

                        <FieldRelationship
                            form={form}
                            label={"User"}
                            name='userId'
                            required={true}
                            model={'Users'}
                            preload={true}
                            defaultValue={form.defaults.userId}
                        />

                    </Col>

                    {products.data && products.data.data.length > 0 &&
                        <Col col={{ xs: 24, md: 16 }}>

                            <OrdersFormItems
                                form={form}
                                products={products.data.data}
                            />

                        </Col>
                    }

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Save"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>

    ), [form.hash, props.process.loading, products.loading]);

}

export default OrdersCreateEditForm;
