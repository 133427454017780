import React, { useMemo, useCallback } from 'react';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import { Form, useForm } from '@dex/bubl-dash/src/lib/functions/useForm';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import ClassesApi from '../ClassesApi';
import { Line, ButtonRow, Button, Panel, Alert, FieldUpload, FieldSwitch } from '@dex/bubl-dash';
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea';
import ClassesSettings from '../ClassesSettings';

const ClassesSingleCancel: React.FC<any> = (props: any) => {

    const app = useApp();

    const [nav] = app.store('nav');

    const { data } = props;

    const form = useForm(data);

    const canCancel = app.userCan(['cancelClasses']);

    const update = ClassesApi.update(data.id, "cancel", {
        onComplete: (response) => {

            app.alert('Class Saved', 'success');

            //navigate to single view after saving and replace current route
            const route = { "type": "Navigation/NAVIGATE", routeName: ClassesSettings.key + "View", params: { id: data.id } }
            app.navigate(nav, 'repalce', route);

        }
    });

    const handleSubmit = useCallback((values) => {

        update.run({ data: values });

    }, [props]);

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Cancel Class"}
                >

                    {!canCancel &&
                        <Alert
                            type={"warning"}
                            message={"Access Denied"}
                            description={"You do not have permission to cancel this class."}
                        />
                    }

                    {canCancel &&
                        <Form
                            form={form}
                            onSubmit={handleSubmit}
                            loading={update.loading}
                        >

                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24, md: 16 }}>

                                    <Row gutter={8} edge={true}>

                                        <Col col={{ xs: 24 }}>

                                            <FieldSwitch
                                                form={form}
                                                name='isCancelled'
                                                label="Cancel Class"
                                                required={true}
                                                defaultValue={form.defaults.isCancelled}
                                            />


                                        </Col>
                                        <Col col={{ xs: 24, md: 14 }}>


                                            {form.values.isCancelled === true && (
                                                <FieldTextArea
                                                    form={form}
                                                    label={"Reason For Cancellation"}
                                                    name='cancelClassReason'
                                                    required={true}
                                                    defaultValue={form.defaults.cancelClassReason}
                                                />
                                            )}



                                        </Col>

                                    </Row>

                                </Col>

                            </Row>

                            <Line />

                            <ButtonRow>
                                <Button
                                    label={"Save"}
                                    loading={update.loading}
                                />
                                <Button
                                    label={"Reset"}
                                    type={"faded"}
                                    onClick={form.reset}
                                />
                            </ButtonRow>

                        </Form>
                    }

                </Panel>

            </Col>

        </Row>

    ), [data, form.hash, update]);

}

export default ClassesSingleCancel;
