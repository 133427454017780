import React, { useMemo } from 'react';
import OrderStageOrderPayment from './OrderStageOrderPayment';


const OrderStages: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <>
            < OrderStageOrderPayment
                data={data}
            />
        </>

    ), [data]);

}

export default OrderStages;
