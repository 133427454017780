import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ProductsSizes from './ProductsSizes';
import { Space } from '@dex/bubl-dash';

const ProductsSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                col={{ xs: 24 }}
            >

                <Panel
                    heading={"Product Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Title",
                                key: "title",
                                format: "titleCase"
                            },
                        ]}
                        columnB={[
                            data.type === "pass" && {
                                label: "No of Classes",
                                key: "noOfClasses",
                                format: "number",
                                hideIfEmpty: true
                            },
                            data.type === "pass" && {
                                label: "No of Socials",
                                key: "noOfSocials",
                                format: "number",
                                hideIfEmpty: true
                            },
                            {
                                label: "Price",
                                key: "price",
                                format: "number",
                                prefix: "RM "
                            },
                        ].filter(Boolean)}
                        columnC={[
                            {
                                label: "Photo",
                                key: "photo",
                                format: "image",
                                hideIfEmpty: true
                            },
                            {
                                label: "Description",
                                key: "description",
                                format: "nl2br"
                            },
                        ]}
                    />

                    {data.type === "merch" &&
                        <ProductsSizes
                            data={data}
                        />
                    }

                </Panel>

            </Col>

        </Row>

    ), [data]);

}

export default ProductsSingleView;
