import StylesIndex from './StylesIndex';
import StylesCreate from './StylesCreate';
import StylesSettings from './StylesSettings';
import StylesSingle from './StylesSingle';

export default function (app) {

    const settings = StylesSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: settings.title,
        addPath: settings.path + "/create",
        addCaps: settings.createCaps,
        priority: 12,
        items: [
            {
                label: "All Styles",
                path: settings.path,
                caps: settings.indexCaps,
            },
        ],
    });

    StylesIndex(app, settings);

    StylesCreate(app, settings);

    StylesSingle(app, settings);

}