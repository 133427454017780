import React, { useMemo } from 'react';

import styles from "./ClassAttendedCard.module.scss";
import { Col, ModelLink, Row, Status, WhiteSpace } from '@dex/bubl-dash';
import { dayDateTime } from '../../functions/formats';

const ClassAttendedCard: React.FC<ClassAttendedCardProps> = (props) => {

    const { attendce, showPass } = props;

    return useMemo(() => (

        <div className={styles.card}>

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, sm: 12, md: 8 }} justify='center'>

                    <span className={styles.date}>
                        <ModelLink data={{ id: attendce?.classId, title: dayDateTime(attendce?.class?.dateTime) }} modelName="Classes" />
                    </span>

                    <br />

                    <span className={styles.style}>
                        <ModelLink data={{ id: attendce?.class?.style?.id, title: attendce?.class?.style?.title }} modelName="Styles" />
                        <WhiteSpace />/<WhiteSpace />
                        <ModelLink data={{ id: attendce?.class?.level?.id, title: attendce?.class?.level?.title }} modelName="Levels" />
                    </span>

                </Col>

                <Col col={{ xs: 24, sm: 12, md: 8 }} justify='center'>

                    {!attendce.hasPass &&
                        <Status prefix={"attendees.pass"} status={"no-pass"} />
                    }

                    {attendce.hasPass && attendce.isRetake &&
                        <Status prefix={"attendees.pass"} status={"retake"} />
                    }

                    {attendce.hasPass && !attendce.isRetake &&
                        <Status prefix={"attendees.pass"} status={"has-pass"} />
                    }

                    {attendce.hasPass &&
                        <>
                            <WhiteSpace /> <WhiteSpace />
                            <ModelLink data={attendce.pass} />
                        </>
                    }


                </Col>

                <Col col={{ xs: 24, sm: 12, md: 8 }} justify='center' className={styles.actions}>

                    <Status prefix={"classes.type"} status={attendce?.class?.type} />
                    <WhiteSpace />
                    <Status prefix={"attendees.status"} status={attendce.status} />

                </Col>

            </Row>

        </div>

    ), [attendce]);

}

interface ClassAttendedCardProps {
    [key: string]: any,
}

export default ClassAttendedCard;