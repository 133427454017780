import React from 'react';
import { titleCase, get } from "@dex/bubl-helpers";
import LevelsApi from './LevelsApi';
import { Status, WhiteSpace } from '@dex/bubl-dash';


let settings;

export default settings = {
    key: 'levels',
    zone: 'studio',
    path: '/levels',
    title: "Levels",
    endpoint: LevelsApi.endpoint,
    modelName: 'Levels',
    idKey: 'id',
    primaryKey: 'title',
    indexCaps: ["viewLevels"],
    viewCaps: ["viewLevels"],
    createCaps: ["createLevels"],
    actions: ['trash', 'clone'],
    indexTitle: (routeName, params, user) => {

        let title: string = settings.title;

        if (params && params.name) title = title + " / " + titleCase(params.name);

        return title;

    },
    indexTabs: (routeName, params, user) => {

        return [
            {
                label: "All",
                path: settings.path
            },
            {
                label: "Add New",
                path: settings.path + "/create"
            },
        ]

    },
    indexButtons: (routeName, params, user) => {

        return [
            {
                label: "Add New",
                path: settings.path + '/create',
                caps: settings.createCaps
            }
        ]

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: LevelsApi.get,
    indexFetchParams: (routeName, params, user) => {

        const filters: any = {
            where: {},
            include: [
                {
                    relation: 'style',
                    preset: "link"
                },
                {
                    relation: 'allowedPasses',
                    preset: "link"
                },
                {
                    relation: 'retakePasses',
                    preset: "link"
                }
            ],
            order: ['created.date DESC'], limit: 10, skip: 0
        };

        if (params && params.status) filters.where.status = { inq: [params.status] };

        return filters;

    },
    indexQuickSearch: (routeName, params, user) => {

        const fields = ['id', 'title'];

        return fields;

    },
    indexSearchFields: (routeName, params, user) => {

        const fields = [
            {
                label: "Title",
                key: "title",
                type: "text",
            },
            {
                label: "Short Title",
                key: "shortTitle",
                type: "text",
            },
            {
                label: "Status",
                key: "status",
                type: "choice",
                options: [
                    { value: "active", label: "Active" },
                    { value: "inactive", label: "Inactive" },
                ]
            },
            {
                label: "Style",
                key: "styleId",
                type: "relationship",
                model: "Styles"
            },
            // {
            //     label: "Allowed Passes",
            //     key: "allowedPassesIds",
            //     type: "relationship",
            //     model: "Products"
            // },
            // {
            //     label: "Retake Passes",
            //     key: "retakePassesIds",
            //     type: "relationship",
            //     model: "Products"
            // },
            {
                label: "Created Date",
                key: "created.date",
                type: "date",
            },
        ];

        return fields;

    },

    indexColumns: (routeName, params, user) => {

        const columns = [
            {
                label: "Title",
                key: "this",
                format: "ModelLink",
                sort: "title"
            },
            {
                label: "Short Title",
                key: "shortTitle",
                sort: "shortTitle",
            },
            {
                label: "Style",
                key: "style",
                format: "ModelLink"
            },
            {
                label: "Status",
                key: "status",
                format: "status"
            },
            {
                label: "Enabled For",
                key: "enabledFor",
                format: "titleCase",
            },
            {
                label: "Created Date",
                key: "created",
                format: "OnAndBy",
                sort: "created.date",
                alignEnd: true
            },
            {
                label: "Actions",
                key: "actions",
                alignEnd: true,
                format: "actions",
                api: LevelsApi,
                settings: settings,
            }
        ];

        return columns;

    },
    singleFetch: LevelsApi.getOne,
    singleFetchParams: (routeName, params, user, fetch) => {

        const filters = {
            include: [
                {
                    relation: 'style',
                    preset: "link"
                },
                {
                    relation: 'allowedPasses',
                    preset: "link"
                },
                {
                    relation: 'retakePasses',
                    preset: "link"
                },
                {
                    relation: 'allowedPassesPractice',
                    preset: "link"
                },
                {
                    relation: 'allowedPassesSocial',
                    preset: "link"
                }
            ]
        };

        return filters;

    },
    singleTitle: (routeName, params, user, fetch) => {

        let title: any = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');

        if (error) title = "Error";
        else if (data) title = <>{data[settings.primaryKey]} <WhiteSpace /> <Status status={data.status} /></>;
        else if (complete && !data) title = "Not Found";

        return title;

    },
    singleTabs: (routeName, params, user, fetch) => {

        const path = settings.path + "/" + params.id;

        return [
            {
                label: "View",
                path: path + "/view",
                caps: settings.viewCaps
            },
            {
                label: "Edit",
                path: path + "/edit",
                caps: settings.createCaps
            },
        ]

    },
    singleButtons: (routeName, params, user, fetch) => {

        return [
        ].filter(Boolean);

    },
};